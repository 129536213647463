import React, { useCallback, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Currency, IPInfo, Product } from '../../types'
import ProductCard from '../Product/ProductCard'
import { IPLocationInShippingCounties } from '../../lib/utils'
import CellProduct from './CellProduct'

type Props = {
  products: Product[]
  shopReference: string
  getQuantity: (...arg: any[]) => number
  onOpenQuantityDialog: (...arg: any[]) => void
  onBuy: (...arg: any[]) => any
  onLike: (productId: string, likes: number) => void
  isProductLiked: (productId: string) => boolean
  ipInfo: IPInfo
  onPreview?: (...arg: any[]) => void
  preview?: Product | undefined
  previewIndex?: number | undefined
  exchangeRate?: number
  currency?: Currency
  getShippingFlatRate?: (productId: string) => number
  onCheckedBrands?: (brandName: string) => void
  checkedBrands?: any
}

const useStyles = makeStyles((theme: any) => ({
  gridView: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(100px, 293px))',
    justifyContent: 'center',
    gridGap: 5,
    padding: 5,
    background: theme.palette.background.default,
  },
  pictureContainer: {
    position: 'relative',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.white,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    padding: 5,
  },
  highlight: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(63, 154, 241, 0.09)',
  },
  picture: {
    maxWidth: '100%',
    maxHeight: 300,
    verticalAlign: 'top',
    objectFit: 'cover',
    objectPosition: '100% 0',
  },
  preview: {
    gridColumn: '3 span',
    display: 'flex',
    justifyContent: 'center',
  },
}))

export function GridView({
  products,
  preview,
  previewIndex,
  onPreview,
  ipInfo,
  getQuantity,
  onOpenQuantityDialog,
  onBuy,
  isProductLiked,
  onLike,
  exchangeRate,
  currency,
  getShippingFlatRate,
  onCheckedBrands,
  checkedBrands,
  shopReference,
}: Props) {
  const classes = useStyles()
  const [oldOffset, setOldOffset] = useState<number>()
  const onScroll = el => {
    el.scrollIntoView()
    window.scrollBy(0, -60)
  }

  const productPreviewRef = useRef(null)

  const onPreviewWrapper = (product: Product, index: number): void => {
    if (onPreview) {
      onPreview(product, index)
      setTimeout(() => {
        if (productPreviewRef.current) {
          setOldOffset(window.pageYOffset)
          window.scrollTo(0, productPreviewRef.current.offsetTop - 60)
        }
      }, 300)
    }
  }

  const close = useCallback(() => {
    if (onPreview) {
      onPreview(null)
      setTimeout(() => {
        window.scrollTo(0, oldOffset)
      }, 300)
    }
  }, [oldOffset, onPreview])

  return (
    <div className={classes.gridView} aria-label="gridView">
      {products.map((product, index) => (
        <React.Fragment key={`${product.id}-2`}>
          <div className={classes.pictureContainer}>
            <CellProduct
              product={product}
              onClick={() => onPreviewWrapper(product, index)}
              scroll={onScroll}
            />
            {preview && preview.id === product.id && (
              <div className={classes.highlight}></div>
            )}
          </div>
          {preview && previewIndex === index && (
            <div ref={productPreviewRef} className={classes.preview}>
              <ProductCard
                listType={'grid-view'}
                shopReference={shopReference}
                ipLocation={ipInfo?.country_name}
                shipToIpLocation={IPLocationInShippingCounties(
                  (ipInfo as any).country,
                  preview.shippingCountries,
                )}
                product={preview}
                quantity={getQuantity(preview.id)}
                onOpenQuantityDialog={() => onOpenQuantityDialog(preview.id)}
                onBuy={onBuy(preview.id, products)}
                onLike={() => onLike(preview.id, preview.likes)}
                isLiked={isProductLiked(preview.id)}
                onClose={close}
                exchangeRate={exchangeRate}
                currency={currency}
                shippingFlatRate={
                  preview
                    ? getShippingFlatRate
                      ? getShippingFlatRate(preview.id)
                      : 0
                    : 0
                }
                onCheckedBrands={onCheckedBrands}
                checkedBrands={checkedBrands}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}
