import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Currency, IPInfo, Product } from '../../types'
import { IPLocationInShippingCounties } from '../../lib/utils'
import ProductCard from '../Product/ProductCard'

type Props = {
  products: Product[]
  shopReference: string
  getQuantity: (...arg: any[]) => number
  onOpenQuantityDialog: (...arg: any[]) => void
  onBuy: (...arg: any[]) => any
  onLike: (productId: string, likes: number) => void
  isProductLiked: (productId: string) => boolean
  ipInfo: IPInfo
  exchangeRate?: number
  currency?: Currency
  getShippingFlatRate?: (productId: string) => number
}

const useStyles = makeStyles((theme: any) => ({
  feedView: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 15,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
    },
  },
}))

export function FeedView({
  products,
  getQuantity,
  onOpenQuantityDialog,
  onBuy,
  isProductLiked,
  onLike,
  ipInfo,
  exchangeRate,
  currency,
  getShippingFlatRate,
  shopReference,
}: Props) {
  const classes = useStyles()

  return (
    <div className={classes.feedView} aria-label="feedView">
      {products.map(product => (
        <ProductCard
          listType={'feed-view'}
          shopReference={shopReference}
          key={product.id}
          ipLocation={ipInfo?.country_name}
          shipToIpLocation={IPLocationInShippingCounties(
            ipInfo.country,
            product.shippingCountries,
          )}
          product={product}
          quantity={getQuantity(product.id)}
          onOpenQuantityDialog={() => onOpenQuantityDialog(product.id)}
          onBuy={onBuy(product.id, products)}
          onLike={() => onLike(product.id, product.likes)}
          isLiked={isProductLiked(product.id)}
          exchangeRate={exchangeRate}
          currency={currency}
          shippingFlatRate={
            getShippingFlatRate ? getShippingFlatRate(product.id) : 0
          }
        />
      ))}
    </div>
  )
}

export default FeedView
