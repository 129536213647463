import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import './index.css'
import { getYoutubeUrlId } from '../../../lib/utils'

type Props = {
  pictures: string[]
  youtubeUrl?: string
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    background: theme.palette.white,
    margin: 'auto',
  },
  image: {
    padding: 10,
    maxWidth: '100%',
    maxHeight: 380,
  },
  swiperContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    zIndex: 1,
    background: theme.palette.white,
    position: 'relative',
    paddingBottom: 40,
    // borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
    '&::after': {
      content: `''`,
      display: 'block',
      borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
    },
  },
  swiper: {
    position: 'relative',
    width: '100%',
    height: 380,
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
  },
}))

export function ProductSlider({ pictures = [], youtubeUrl }: Props) {
  const classes = useStyles()

  const youtubeVideoID = getYoutubeUrlId(youtubeUrl)
  const itemsLength = Number(pictures.length) + Number(youtubeVideoID ? 1 : 0)
  const params = {
    lazy: true,
    pagination:
      itemsLength > 1
        ? {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            dynamicBullets: true,
          }
        : {},
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore*/}
      {/* @ts-ignore */}
      <Swiper
        wrapperClass={classes.swiper}
        containerClass={classes.swiperContainer}
        {...params}
      >
        {pictures && pictures[0] && (
          <div key={pictures[0]} className={classes.container}>
            <img alt="product" src={pictures[0]} className={classes.image} />
          </div>
        )}

        {youtubeVideoID ? (
          <div key="youtube-url" className={classes.container}>
            <div>
              <iframe
                title={youtubeVideoID}
                className={classes.image}
                src={`https://www.youtube.com/embed/${youtubeVideoID}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        ) : (
          <Fragment />
        )}

        {pictures &&
          (pictures as any).slice(1).map((picture: string) => (
            <div key={picture} className={classes.container}>
              <img alt="product" src={picture} className={classes.image} />
            </div>
          ))}
      </Swiper>
    </>
  )
}

export default ProductSlider
