import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { useGetShopByUrl } from '../../hooks/useGetShopByUrl'
import { Country, Currency } from '../../types'

type Props = {
  children: React.ReactElement[] | React.ReactElement
  shopUrl?: string
  /** Save in store function  */
  setDataInStore?: (data: {
    country?: Country
    currency?: Currency
    exchangeRate?: number
  }) => void
  /** Data **/
  country?: Country
  currency?: Currency
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    maxWidth: 800,
    margin: 'auto',
  },
}))

export function Template({
  children,
  shopUrl = null,
  setDataInStore,
  country,
  currency,
}: Props) {
  const classes = useStyles()

  const { shop } = useGetShopByUrl(shopUrl)

  return (
    <div className={classes.container}>
      <Header
        shopName={shop?.shopName}
        shopUrl={shopUrl}
        setDataInStore={setDataInStore}
        country={country}
        currency={currency}
      />
      {children}
      <Footer shopUrl={shopUrl} />
    </div>
  )
}

export default Template
