import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button/Button'
import { Formik } from 'formik'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import ReactCountryFlag from 'react-country-flag'
import { object, string } from 'yup'

import Title from '../Title/Title'
import DialogContainer from '../DialogContainer/DialogContainer'
import { getAllCountries, getCountryByCode } from '../../lib/getCountryData'
import { getCurrenciesByCountryCode } from '../../lib/getCurrencyData'
import { Country, Currency } from '../../types'

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    color: '#000',
    marginTop: 30,
    marginBottom: 5,
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontSize: 12,
    lineHeight: '16px',
    color: '#999999',
    letterSpacing: '-0.02em',
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 25,
    paddingBottom: 30,
    paddingTop: 10,
  },
  input: {
    marginBottom: 15,
    marginTop: 5,
    minWidth: 250,
  },
  rootStyle: {
    height: 50,
    borderRadius: 10,
    background: '#9a9a9a08',
  },
  labelStyle: {
    top: -1,
    fontFamily: 'Nunito',
    fontSize: 16,
    letterSpacing: '-0.02em',
    color: '#999999',
  },
  item: {
    height: 30,
  },
  selectStyle: {
    '&::focus': {
      borderRadius: 10,
    },
  },
  button: {
    ...theme.styles.button,
    height: 50,
    marginTop: 5,
    ...theme.styles.blueButton,
  },
}))

type Props = {
  /** list of currencies */
  // currencies?: Array<Currency>
  /** on Select currency */
  onSelect: ({
    country,
    currency,
  }: {
    country?: Country
    currency?: Currency
  }) => void
  /** default selected country code */
  defaultCountryCode?: string
  /** callback when the dialog close */
  onClose: (...args: any[]) => void
  /** is the dialog open? */
  open: boolean
}

const validationSchema = object({
  country: string().required('Required'),
})

export function SelectCountryCurrencyDialog({
  onSelect,
  defaultCountryCode = '',
  onClose,
  open,
}: Props) {
  const classes = useStyles()
  const countries = getAllCountries()

  const renderMenuItem = (country: Country) => {
    const currencies = getCurrenciesByCountryCode(country.code)

    if (currencies && currencies[0]) {
      const currency = currencies[0]
      return (
        <MenuItem
          key={country.code}
          value={country.code}
          className={classes.item}
          data-testid={country.code}
        >
          <ReactCountryFlag
            styleprops={{
              width: '20px',
              height: '20px',
            }}
            countryCode={country.code}
            svg
          />
          &nbsp; &nbsp;
          {country.name} ({currency.code})
        </MenuItem>
      )
    }
    return null
  }

  const handleSubmit = values => {
    if (values && values.country) {
      const country = getCountryByCode(values.country)
      const currencies = getCurrenciesByCountryCode(values.country)
      let currency
      if (currencies && currencies[0]) {
        currency = currencies[0]
      }
      onSelect({
        country,
        currency,
      })
    }
  }

  return (
    <DialogContainer open={open} onClose={onClose}>
      <div className={classes.root}>
        <Title title="Currency" onClose={onClose} />
        <span className={classes.subTitle}>
          Select view-currency, charge will be in USD
        </span>

        <Formik
          initialValues={{ country: defaultCountryCode }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            errors,
          }: any) => (
            <div className={classes.container}>
              <TextField
                id="country-currency-select"
                classes={{ root: classes.rootStyle }}
                className={classes.input}
                variant="outlined"
                label="Country"
                name="country"
                InputProps={{ classes: { root: classes.rootStyle } }}
                InputLabelProps={{ classes: { root: classes.labelStyle } }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.country}
                error={touched.country && !!errors.country}
                select
                SelectProps={{ classes: { root: classes.selectStyle } }}
              >
                {countries.map(renderMenuItem)}
              </TextField>

              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleSubmit}
                data-testid="country-currency-ok"
              >
                Ok
              </Button>
            </div>
          )}
        </Formik>
      </div>
    </DialogContainer>
  )
}

export default SelectCountryCurrencyDialog
