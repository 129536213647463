import { Address, Country, Currency, Variant } from '../../types'
import { getProvinces } from '../../lib/getCountryData'

type Action =
  | { type: 'SET_QUANTITY'; payload: { productId: string; quantity: number } }
  | {
      type: 'SET_COUNTRY'
      payload: { country: Country }
    }
  | {
      type: 'SET_CURRENCY'
      payload: { currency: Currency }
    }
  | {
      type: 'SET_CURRENCY_EXCHANGE_RATE'
      payload: { rate: number }
    }
  | {
      type: 'SET_SHIPPING_COUNTRY'
      payload: { country: string; province: string }
    }
  | { type: 'SET_VARIANT'; payload: { variant: Variant } }
  | { type: 'SET_SHIPPING_DETAILS'; payload: { details: any } }
  | { type: 'SET_BILLING_DETAILS'; payload: { details: any } }
  | { type: 'SET_ORDER_ID'; payload: { orderId: string } }
  | {
      type: 'SET_BUYER_ACCEPTS_MARKETING'
      payload: { buyerAcceptsMarketing: boolean }
    }

export interface AppState {
  quantities: any
  shippingDetails: Address
  billingDetails: Address
  variant: Variant
  orderId?: string
  buyerAcceptsMarketing?: boolean
  country: Country
  currency: Currency
  rate: number
}

export const initState = {
  quantities: {},
  shippingDetails: {},
  billingDetails: {},
  variant: {},
  buyerAcceptsMarketing: true,
  country: null,
  currency: null,
  rate: 1,
}

export function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case 'SET_QUANTITY':
      return {
        ...state,
        quantities: {
          ...state.quantities,
          [action.payload?.productId]: action.payload?.quantity || 0,
        },
      }
    case 'SET_COUNTRY':
      return {
        ...state,
        country: { ...state.country, ...action.payload.country },
      }
    case 'SET_CURRENCY':
      return {
        ...state,
        currency: { ...state.currency, ...action.payload.currency },
      }
    case 'SET_CURRENCY_EXCHANGE_RATE':
      return {
        ...state,
        rate: action.payload.rate,
      }
    case 'SET_SHIPPING_COUNTRY':
      return {
        ...state,
        shippingDetails: {
          ...state.shippingDetails,
          country: action.payload?.country,
          province:
            getProvinces(action.payload?.country).length > 0
              ? action.payload?.province
              : '',
        },
      }
    case 'SET_VARIANT':
      return {
        ...state,
        variant: action.payload?.variant,
      }
    case 'SET_SHIPPING_DETAILS':
      return {
        ...state,
        shippingDetails: action.payload?.details,
      }
    case 'SET_BILLING_DETAILS':
      return {
        ...state,
        billingDetails: action.payload?.details,
      }
    case 'SET_ORDER_ID':
      return {
        ...state,
        orderId: action.payload?.orderId,
      }
    case 'SET_BUYER_ACCEPTS_MARKETING':
      return {
        ...state,
        buyerAcceptsMarketing: action.payload?.buyerAcceptsMarketing,
      }
    default:
      return state
  }
}
